import {
  AENCADRER,
  AFTERNOON_TRIP_LABEL,
  AUTONOMIE,
  BAPTEME,
  DAY_TRIP_LABEL,
  ENCADRANT,
  FORMATION,
  MORNING_TRIP_LABEL,
  MOTIF_ITEM,
  SEPARATOR,
  TWILIGHT_TRIP_LABEL,
} from "./constants"
import dayjs from "dayjs"
import { useDaysStore } from "./stores/days.store"

const MORNING_SHORT_TRIP_LABEL = "Mat"
const AFTERNOON_SHORT_TRIP_LABEL = "Aprem"
const TWILIGHT_SHORT_TRIP_LABEL = "Crep"
const NIGHT_TRIP_LABEL = "Nuit"

export function formatPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/\B(?=(\d{2})+(?!\d))(?<!\+3)|\B(?<=\+33)/g, " ")
}

export function toCapitelize(str) {
  let capitelizeStr = ""
  try {
    capitelizeStr = str.charAt(0).toUpperCase() + str.slice(1)
  } catch (e) {
    return str
  }
  return capitelizeStr
}

export const periodsOrder = [
  MORNING_SHORT_TRIP_LABEL,
  AFTERNOON_SHORT_TRIP_LABEL,
  TWILIGHT_SHORT_TRIP_LABEL,
  NIGHT_TRIP_LABEL,
]

export function getPeriodsLabel(time, isDayTrip, short = false) {
  let hour = dayjs(time).hour()

  if (isDayTrip) {
    return DAY_TRIP_LABEL
  } else {
    switch (true) {
      case hour <= 12:
        return short ? MORNING_SHORT_TRIP_LABEL : MORNING_TRIP_LABEL

      case hour < 17:
        return short ? AFTERNOON_SHORT_TRIP_LABEL : AFTERNOON_TRIP_LABEL

      case hour <= 19:
        return short ? TWILIGHT_SHORT_TRIP_LABEL : TWILIGHT_TRIP_LABEL

      default:
        return NIGHT_TRIP_LABEL
    }
  }
}

export const getMargin = (index) => {
  switch (index) {
    case 0:
      return "left-2"
    case 1:
      return `left-1/4`
    case 2:
      return `left-2/4`
    case 3:
      return `left-3/4`
    default:
      return "left-2"
  }
}

export function getColorPercent(trip) {
  try {
    const { tripDiversPercent, isTripFull } = trip
    const percentValue = parseInt(tripDiversPercent, 10)

    if (isTripFull || percentValue >= 100) {
      return "bg-gray-600 dark:bg-gray-700"
    }

    if (percentValue === 0) {
      return "bg-gray-200 dark:bg-gray-700"
    }

    if (percentValue <= 40) {
      return "bg-green-200 dark:bg-green-500/50"
    }

    if (percentValue <= 60) {
      return "bg-yellow-200 dark:bg-yellow-500/80"
    }

    // Si percentValue est compris entre 60 et 100 (exclus)
    return "bg-red-200 dark:bg-red-500/50"
  } catch (error) {
    return "bg-gray-200 dark:bg-gray-600"
  }
}

/**
 * Forcer le refresh du calendrier
 */
export const refresh = () => {
  useDaysStore.setState({ refresh: true })
}
