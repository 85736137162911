import React, { memo, useMemo } from "react"
import { getColorPercent } from "../../../common/utils"
import clsx from "clsx"
import Closed from "./Closed"
import Indicator from "./Indicator"

const Trip = memo(function Trip({ trip }) {
  const { isTripFullEnc, isTripClosed } = trip

  const colorClass = useMemo(() => getColorPercent(trip), [trip])

  const containerClasses = useMemo(
    () => clsx("relative h-full w-full",
      isTripClosed ? "bg-gray-200 dark:bg-gray-700" : colorClass
      ),
    [colorClass],
  )

  return (
    <div className={containerClasses}>
      {isTripClosed && <Closed />}
      {isTripFullEnc && !isTripClosed && <Indicator color={"bg-green-600"} />}
    </div>
  )
})

export default Trip
