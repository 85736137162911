import React, { memo, useEffect, useRef, useState } from "react"
import Day from "../Day/Day"
import { handleData, useDaysStore } from "../../../common/stores/days.store"
import { Keyboard, Manipulation, Pagination, Virtual } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import clsx from "clsx"
import { useRequest } from "../../../services/UseRequest"
import dayjs from "dayjs"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { useShallow } from "zustand/react/shallow"
import { initMonth } from "../../../common/calendarUtils"

const SwiperContent = memo(function SwiperContent({
  updateProperties,
  deleteTrip,
}) {
  const { selectedDate, setSelectedDate } = useDaysStore(
    useShallow((s) => ({
      selectedDate: s.selectedDate,
      setSelectedDate: s.setSelectedDate,
    })),
  )

  const [calState, setCalState] = useState({
    currentYear: dayjs(selectedDate).year(),
    currentMonthIdx: dayjs(selectedDate).month(),
  })
  const swiperRef = useRef(null)
  const { onHandleDate } = useRequest()

  const [slides, setSlides] = useState(
    initMonth(calState.currentYear, calState.currentMonthIdx),
  )

  // useEffect(() => {
  //   setSlides(initMonth(calState.currentYear, calState.currentMonthIdx))
  // }, [selectedDate])

  useEffect(() => {
    const index = slides.findIndex((day) =>
      dayjs(day.date).isSame(dayjs(selectedDate), "day"),
    )
    swiperRef.current.slideTo(index, 0, false)
    handleData(slides[0].date, onHandleDate, slides.length)
  }, [slides])

  /**
   * Gère le changement de slide
   * @param e
   */
  const handleSlideChange = (e) => {
    if (!swiperRef.current) return

    const activeIndex = swiperRef.current.activeIndex
    if (activeIndex === undefined) return

    const activeDay = slides[activeIndex]
    setSelectedDate(activeDay.date)

    setCalState({
      currentYear: dayjs(activeDay.date).year(),
      currentMonthIdx: dayjs(activeDay.date).month(),
    })

    if (
      activeIndex === 0 ||
      activeIndex === slides.length - swiperRef.current.params.slidesPerView
    ) {
      setSlides(initMonth(calState.currentYear, calState.currentMonthIdx))
    }
  }

  return (
    <div
      className={clsx(
        "p-0 lg:p-2 lg:pb-0",
        "h-[calc(100%-56px)] overflow-hidden lg:h-[calc(100%-60px)]",
      )}
    >
      <Swiper
        onSlideChange={handleSlideChange}
        modules={[Keyboard, Manipulation, Pagination, Virtual]}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        initialSlide={slides.findIndex((day) =>
          day.date.isSame(selectedDate, "day"),
        )}
        spaceBetween={5}
        breakpoints={{
          768: { slidesPerView: 1 },
          1024: { slidesPerView: 2 },
          1200: { slidesPerView: 3 },
          1440: { slidesPerView: 4 },
          1600: { slidesPerView: 5 },
          2160: { slidesPerView: 7 },
        }}
        keyboard={{ enabled: true }}
        // pagination={{ type: "fraction" }}
        virtual
      >
        {slides.map((day, index) => {
          const date = day.date
          return (
            <SwiperSlide key={date}>
              <Day
                date={date}
                updateProperties={updateProperties}
                deleteTrip={deleteTrip}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
})

export default SwiperContent
