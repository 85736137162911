import React, { memo, useCallback, useMemo } from "react"
import Date from "./Date"
import clsx from "clsx"
import Closed from "./Closed"
import { handleDate } from "../../../common/stores/days.store"
import { sidebarStore } from "../../../common/stores/sidebar.store"
import { useShallow } from "zustand/react/shallow"
import dayjs from "dayjs"
import Trip from "./Trip"
import TripBooking from "./TripBooking"

const Day = memo(function Day({ day, dataDay, readOnly, classDefault }) {
  const { date, isCurrentMonth } = day
  const { isDayClosed = false, trips = [] } = dataDay || {}
  const { toggleMobile } = sidebarStore(
    useShallow((s) => ({
      toggleMobile: s.toggleMobile,
    })),
  )

  const handleDateClick = useCallback(
    (date) => {
      if (readOnly) return

      handleDate(date, true)
      toggleMobile()
    },
    [date],
  )

  const tripComponents = useMemo(() => {
    return trips.map((trip) => {
      const key = trip?.tripId

      if (readOnly) {
        return <TripBooking key={key} trip={trip} />
      } else {
        return <Trip key={key} trip={trip} />
      }
    })
  }, [trips, readOnly])

  return (
    <button
      type="button"
      className={clsx(
        // "border border-white dark:border-gray-600",
        "relative overflow-hidden",
        "flex items-center justify-center",
        readOnly ? "h-14" : "h-[48px] lg:h-12",
        !isCurrentMonth && "opacity-50",
        readOnly
          ? classDefault
          : (isDayClosed && "bg-gray-200 dark:bg-gray-700") || "bg-white dark:bg-gray-800",
        readOnly && "cursor-default",
      )}
      onClick={() => handleDateClick(date)}
    >
      {isDayClosed ? (
        <Closed />
      ) : (
        <div
          className={clsx(
            "calItem flex h-full w-full items-center",
            "divide-x divide-white/80 dark:divide-gray-600",
          )}
        >
          {tripComponents}
        </div>
      )}
      <Date
        date={date}
        readOnly={readOnly}
        isToday={date.isSame(dayjs(), "day")}
        isCurrentMonth={isCurrentMonth}
      />
    </button>
  )
})
export default Day
